<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      v-bind:style="{
        'background-image': 'url(' + section.backgroundImage + ') ',
      }"
      class="
        component
        position-relative
        c-stories
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div class="row padding-x-12 position-relative z-index-3">
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div
        class="
          row
          padding-t-24 padding-b-12 padding-x-12
          position-relative
          z-index-3
        "
      >
        <div
          class="
            flex-item-1
            width-100
            s-common__interactions
            c-stories__interactions
            display-block
            bp-768:display-flex
            position-relative
            bp-768:justify-content-center
          "
        >
          <div
            class="
              s-common__filter
              width-100
              bp-768:width-auto
              c-stories__filters
            "
          >
            <button
              class="
                flex-item-1
                bp-768:flex-item-0
                s-common__filter-button
                c-stories__filter-button
                font-size-18
                line-height-125
              "
              :class="{ 'is-active': active_filter === 'featured' }"
              @click="filter_players('featured')"
            >
              <feather
                class="margin-r-6"
                size="16px"
                type="file-text"
              ></feather>
              Featured
            </button>
            <button
              class="
                flex-item-1
                bp-768:flex-item-0
                s-common__filter-button
                c-stories__filter-button
                font-size-18
                line-height-125
              "
              :class="{
                'is-active': active_filter === 'latest',
              }"
              @click="filter_players('latest')"
            >
              <feather class="margin-r-6" size="16px" type="clock"></feather>
              Latest
            </button>
          </div>
          <div
            class="
              margin-t-24
              width-100
              display-flex
              justify-content-center
              bp-768:width-auto bp-768:margin-t-0 bp-768:margin-l-0
              s-common__tabs
              bp-768:position-absolute bp-768:right-0
              c-stories__tabs
              align-self-center
            "
          >
            <button
              class="s-common__tab c-stories__tab font-size-14 line-height-150"
              :class="{
                'is-active': active_view === 'list',
              }"
              @click="change_view('list')"
            >
              <div class="s-common__tab--dot c-stories__tab--dot"></div>
              <span
                >List
                <span
                  class="
                    display-inline
                    bp-768:display-none
                    bp-1024:display-inline
                  "
                  >View</span
                ></span
              >
            </button>
            <button
              class="s-common__tab c-stories__tab font-size-14 line-height-150"
              :class="{
                'is-active': active_view === 'cards',
              }"
              @click="change_view('cards')"
            >
              <div class="s-common__tab--dot c-stories__tab--dot"></div>
              <span
                >Card
                <span
                  class="
                    display-inline
                    bp-768:display-none
                    bp-1024:display-inline
                  "
                  >View</span
                ></span
              >
            </button>
          </div>
        </div>
      </div>
      <div
        class="c-stories__content padding-y-6 row position-relative z-index-3"
      >
        <div class="width-100" v-show="active_filter === 'featured'">
          <div
            v-show="active_view === 'cards'"
            class="padding-x-6 c-stories__card"
          >
            <div class="flex-item-1 row" v-if="section.stories">
              <div
                v-for="story in section.stories.slice(0, 6)"
                :key="story.id"
                class="
                  c-stories__card--item
                  display-flex
                  padding-6
                  width-100
                  bp-768:width-50
                  bp-1024:width-33
                  bp-1280:width-25
                "
              >
                <div class="c-stories__card--item-inside flex-item-1">
                  <div class="c-stories__card--item-image-container">
                    <div
                      v-if="story.image"
                      class="c-stories__card--item-image"
                      v-bind:style="{
                        'background-image':
                          'url(' + story.image + '?width=768) ',
                      }"
                    ></div>
                  </div>
                  <div
                    class="
                      c-stories__card--item-details
                      display-flex
                      flex-direction-column
                      align-items-flex-start
                    "
                  >
                    <h3
                      v-if="story.headline"
                      class="
                        c-stories__card--item-title
                        font-size-21
                        line-height-125
                        font-weight-700
                      "
                    >
                      <a
                        class="c-stories__card--item-url"
                        v-bind:href="story.url"
                        >{{ story.headline }}</a
                      >
                    </h3>
                    <div
                      v-if="story.teaser"
                      v-html="story.teaser"
                      class="
                        c-stories__card--item-teaser
                        flex-item-1
                        font-size-14
                        line-height-175
                      "
                    ></div>
                    <a
                      class="
                        s-common__button s-common__button-primary
                        c-stories__card--item-more
                        font-weight-700 font-size-12
                        line-height-125
                      "
                      v-bind:href="story.url"
                      >Read Full Story</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="active_view === 'list'"
            class="padding-x-12 margin-y-6 c-stories__list"
          >
            <div v-if="section.stories">
              <div
                v-for="story in section.stories.slice(0, 6)"
                :key="story.id"
                class="c-stories__list--item display-flex"
              >
                <div
                  class="c-stories__list--item-inside display-flex flex-item-1"
                >
                  <div class="c-stories__list--item-image-container">
                    <div
                      v-if="story.image"
                      class="c-stories__list--item-image"
                      v-bind:style="{
                        'background-image':
                          'url(' + story.image + '?width=768) ',
                      }"
                    ></div>
                  </div>
                  <div
                    class="
                      c-stories__list--item-details
                      flex-item-1
                      display-flex
                      justify-content-center
                      flex-direction-column
                      align-items-flex-start
                    "
                  >
                    <h3
                      v-if="story.headline"
                      class="
                        c-stories__list--item-title
                        font-size-18
                        line-height-125
                        font-weight-700
                      "
                    >
                      <a
                        class="c-stories__list--item-url"
                        v-bind:href="story.url"
                        >{{ story.headline }}</a
                      >
                    </h3>
                    <div
                      v-if="story.teaser"
                      v-html="story.teaser"
                      class="
                        c-stories__list--item-teaser
                        font-size-14
                        line-height-175
                      "
                    ></div>
                    <a
                      class="
                        s-common__button s-common__button-primary
                        c-stories__card--item-more
                        font-weight-700 font-size-12
                        line-height-125
                      "
                      v-bind:href="story.url"
                      >Read Full Story</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="width-100" v-show="active_filter === 'latest'">
          <div
            v-show="active_view === 'cards'"
            class="padding-x-6 c-stories__card"
          >
            <div class="flex-item-1 row" v-if="latest_stories">
              <div
                v-for="story in latest_stories"
                :key="story.story_id"
                class="
                  c-stories__card--item
                  display-flex
                  padding-6
                  width-100
                  bp-768:width-50
                  bp-1024:width-33
                  bp-1280:width-25
                "
              >
                <div class="c-stories__card--item-inside flex-item-1">
                  <div class="c-stories__card--item-image-container">
                    <div
                      v-if="story.story_image"
                      class="c-stories__card--item-image"
                      v-bind:style="{
                        'background-image':
                          'url(' + story.story_image + '?width=768) ',
                      }"
                    ></div>
                  </div>
                  <div
                    class="
                      c-stories__card--item-details
                      display-flex
                      flex-direction-column
                      align-items-flex-start
                    "
                  >
                    <h3
                      v-if="story.story_headline"
                      class="
                        c-stories__card--item-title
                        font-size-21
                        line-height-125
                        font-weight-700
                      "
                    >
                      <a
                        class="c-stories__card--item-url"
                        v-bind:href="story.story_path"
                        >{{ story.story_headline }}</a
                      >
                    </h3>
                    <div
                      v-if="story.story_summary"
                      v-html="story.story_summary"
                      class="
                        c-stories__card--item-teaser
                        flex-item-1
                        font-size-14
                        line-height-150
                      "
                    ></div>
                    <a
                      class="
                        s-common__button s-common__button-primary
                        c-stories__card--item-more
                        font-weight-700 font-size-12
                        line-height-125
                      "
                      v-bind:href="story.story_path"
                      >Read Full Story</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="active_view === 'list'"
            class="padding-x-12 margin-y-6 c-stories__list"
          >
            <div v-if="latest_stories">
              <div
                v-for="story in latest_stories"
                :key="story.story_id"
                class="c-stories__list--item display-flex"
              >
                <div
                  class="c-stories__list--item-inside display-flex flex-item-1"
                >
                  <div class="c-stories__list--item-image-container">
                    <div
                      v-if="story.story_image"
                      class="c-stories__list--item-image"
                      v-bind:style="{
                        'background-image':
                          'url(' + story.story_image + '?width=768) ',
                      }"
                    ></div>
                  </div>
                  <div
                    class="
                      c-stories__list--item-details
                      display-flex
                      justify-content-center
                      flex-direction-column
                      align-items-flex-start
                    "
                  >
                    <h3
                      v-if="story.story_headline"
                      class="
                        c-stories__list--item-title
                        font-size-18
                        line-height-125
                        font-weight-700
                      "
                    >
                      <a
                        class="c-stories__list--item-url"
                        v-bind:href="story.story_path"
                        >{{ story.story_headline }}</a
                      >
                    </h3>
                    <div
                      v-if="story.story_summary"
                      v-html="story.story_summary"
                      class="
                        c-stories__list--item-teaser
                        font-size-14
                        line-height-175
                      "
                    ></div>
                    <a
                      class="
                        s-common__button s-common__button-primary
                        c-stories__card--item-more
                        font-weight-700 font-size-12
                        line-height-125
                      "
                      v-bind:href="story.url"
                      >Read Full Story</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding-x-24 margin-t-12 position-relative z-index-3">
        <a
          class="
            s-common__button s-common__button-large
            c-stories__more
            font-size-14
            line-height-125
            font-weight-700
            display-flex
            margin-l-auto margin-r-auto
            align-items-center
          "
          :href="'/archives?path=' + $root.gameday.sport.shortname"
          ><feather class="margin-r-12" size="16px" type="file-text"></feather>
          View All Stories</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import SectionBase from "./SectionBase";
import Client from "@/client";
import CommonHeader from "@/components/common/CommonHeader.vue";
export default {
  extends: SectionBase,
  name: "SectionStories",
  components: { CommonHeader },
  data: () => ({
    active_view: "cards",
    active_filter: "featured",
    latest_stories: null,
  }),
  methods: {
    change_view(view) {
      this.active_view = view;
    },
    filter_players(filter) {
      this.active_filter = filter;
    },
    get_latest_stories() {
      Client.get(
        "/services/archives.ashx/stories?index=1&page_size=6&season=0&search=",
        {
          params: {
            sport: this.$root.gameday.sport.shortname,
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          },
        }
      ).then((response) => {
        if (response && response.data && response.data.data) {
          this.latest_stories = response.data.data;
        }
      });
    },
  },
  created() {
    this.get_latest_stories();
  },
};
</script>

<style scoped lang="scss">
.c-stories {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
  &__card {
    &--item {
      &-inside {
        color: $main-font-color;
        background: $main-color;
        border: 1px solid $main-border-color;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
      &-details {
        padding: 36px;
        flex: 1;
      }
      &-teaser {
        margin: 0 0 24px 0;

        a {
          color: $main-font-color;
        }
      }
      &-image-container {
        flex-shrink: 0;
      }
      &-image {
        height: 0;
        padding-bottom: 56.25%;
        width: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: top center;
      }
      &-title {
        margin: 0 0 12px 0;
        position: relative;
        color: $main-font-color;
      }
      &-url {
        color: $main-font-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        width: 50% !important;
        .c-stories__card--item {
          &-inside {
            display: flex;
            flex-direction: row;
          }
          &-details {
            width: 50%;
          }
          &-image-container {
            width: 50%;
          }
          &-image {
            height: 0;
            padding-bottom: 114.94252287%;
            width: 100%;
            overflow: hidden;
            background-size: cover;
            background-position: top center;
          }
        }
        @media screen and (max-width: 1279px) {
          .c-stories__card--item {
            &-inside {
              flex-direction: column;
            }
            &-teaser {
              margin: 0 0 24px 0;
              a {
                color: $main-font-color;
              }
            }
            &-details {
              width: auto;
            }
            &-image-container {
              flex-shrink: 0;
              width: auto;
            }
            &-image {
              height: 0;
              padding-bottom: 56.25%;
              width: 100%;
              overflow: hidden;
              background-size: cover;
              background-position: top center;
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100% !important;
        }
      }
    }
  }
  &__list {
    &--item {
      margin: 0 0 12px 0;
      &:last-child {
        margin: 0;
      }
      &-inside {
        flex: 1;
        position: relative;
        color: $main-font-color;
        background: $main-color;
        border: 1px solid $main-border-color;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        min-height: 200px;
      }
      &-details {
        padding: 36px;
        flex: 1;
      }
      &-teaser {
        margin: 0 0 24px 0;
        a {
          color: $main-font-color;
        }
      }
      &-image-container {
        flex-shrink: 0;
        width: 360px;
      }
      &-image {
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: top center;
      }
      &-title {
        margin: 0 0 12px 0;
        position: relative;
        a {
          color: $main-font-color;
        }
      }
      &-url {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 767px) {
        &-inside {
          min-height: auto;
        }
        &-image-container {
          display: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
[data-theme="dark"] {
  .c-stories {
    &__list {
      &--item {
        &-inside {
          background: #0d0d0d;
        }
      }
    }
  }
}
</style>
